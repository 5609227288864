body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker{
  width: 100% !important;
  border: 1px solid #E9ECF2;
  border-radius: 8px;
}
.react-datepicker__month-container{
  float: none !important;
}
.react-datepicker__header{
  background-color: #fff !important;
  padding-top:28px !important;
  border-bottom: none !important;
}
.react-datepicker__navigation-icon--previous::before{
  border-color: black !important;
  border-width: 1px 1px 0 0 !important;
}
.react-datepicker__navigation-icon--next::before{
  border-color: black !important;
  border-width: 1px 1px 0 0 !important;
}
.react-datepicker__navigation{
  top:25px !important;
}
.react-datepicker__navigation--previous{
  left:15px !important;
}
.react-datepicker__navigation--next{
  right:15px !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-family: "pc_regular" !important;
  font-size: 14px !important;
}
.react-datepicker__header__dropdown{
  padding: 10px 12px !important;
  display: flex !important;
}
.react-datepicker__month-select{
  background-color: #DEE5EC;
  padding: 7px 6px 7px;
  width: 100%;
  border-radius: 6px;
  border:none;
}
.react-datepicker__month-dropdown-container{
  width: 100% !important;
}
.react-datepicker__year-dropdown-container{
  width: 60% !important;
}
.react-datepicker__year-select{
  background-color: #DEE5EC;
  padding: 7px 6px 7px;
  width: 100%;
  border-radius: 6px;
  border:none;
}
.react-datepicker__day-names, .react-datepicker__week{
  display: flex;
  justify-content: space-evenly;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: #2A60BC !important;
  border-radius: 50% !important;
}
.react-datepicker__day--keyboard-selected{
  background-color: #2A60BC !important;
  border-radius: 50% !important;
}
.react-datepicker__day-name{
  color:#b6c1cb !important;
}
.react-datepicker__day--outside-month {
  color: #B6C1CB !important;
  pointer-events: none;
}
.react-datepicker-popper{
  border: 1px solid #E9ECF2;
  border-radius: 8px;
}
.react-datepicker__input-container {
  /* height: 68px */
}

.date-picker {
  border-radius: 1%;
}
